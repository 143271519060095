const updateWindowVariableFromResultIfDefined = (result, variableName, resultKey) => {
  window[variableName] = result[resultKey] === undefined ? window[variableName] : result[resultKey];
};

export const processPageAttributes = (result) => {
  // List of common window variable mappings
  const commonWindowVariables = [
    {variableName: 'cards_page', resultKey: 'cards_page'},
    {variableName: 'category_name', resultKey: 'category_name'},
    {variableName: 'category_id', resultKey: 'category_id'},
    {variableName: 'gallery_query', resultKey: 'query'},
    {variableName: 'is_favorites_page', resultKey: 'is_favorites'},
  ];

  commonWindowVariables.forEach(({variableName, resultKey}) => {
    updateWindowVariableFromResultIfDefined(result, variableName, resultKey);
  });

  // Process SEO and meta attributes if present
  if (result.seo_description_copy) {
    // List of SEO window variable mappings
    const seoWindowVariables = [
      {variableName: 'seo_description', resultKey: 'seo_description_copy'},
      {variableName: 'seo_display_name', resultKey: 'seo_display_name'},
      {variableName: 'top_of_page_copy', resultKey: 'top_of_page_copy'},
      {variableName: 'top_of_page_img_desk', resultKey: 'top_of_page_img_desk'},
      {variableName: 'top_of_page_img_mob', resultKey: 'top_of_page_img_mob'},
    ];

    seoWindowVariables.forEach(({variableName, resultKey}) => {
      updateWindowVariableFromResultIfDefined(result, variableName, resultKey);
    });

    // Meta attributes
    document.querySelector('title').innerHTML = result.meta_title;
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute('content', result.meta_description);
    }

    const robots = document.querySelector('meta[name="robots"]');
    if (result.noindex_url && !robots) {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);
    } else if (!result.noindex_url && robots) {
      robots.remove();
    }
  }
};
