import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import './MWExpandedCategoryView.sass';
import {PB_Chevron} from '~/common/svg/PB_Chevron';

export const MWExpandedCategoryView = (props) => {
  const [openOverride, setOpenOverride] = useState(false);

  const handleClick = (e) => {
    const {category_names: cn} = props;
    const {inVariation: is} = evite.experiments;

    if (is('dyo_uyo_landing', 'landing') && cn.includes('design_your_own')) {
      e.preventDefault();
      window.location.href = '/upload-your-own';
    }
  };

  const renderCategories = (className) =>
    props.categories.map((c) => (
      <div key={c.name} className={className}>
        <a
          onClick={handleClick}
          href={
            c.href
              ? c.href
              : `/gallery/category/${c.name}/?source_meganav&filter=${props.type}&cat=${props.id}`
          }
          className="mw-expanded-category-view__mw-nav-link"
          data-qa-id={`${c.name}_subcategoryLink`}
        >
          {c.display_name}
        </a>
      </div>
    ));

  useEffect(() => {
    if (!props.open) {
      setOpenOverride(false);
    }
  }, [props.open]);

  const toggle = (name) => {
    if (props.onToggle) {
      props.onToggle(name);
    } else {
      setOpenOverride(!openOverride);
    }
  };

  const open = props.open || openOverride;

  return (
    <div key={props.name} className="mw-subcategory">
      {props.visible ? (
        <>
          <button
            className="unstyled-button expandable-subcategory"
            onClick={() => toggle(props.name)}
            tabIndex={props.linkTabIndex}
            data-qa-id={`${props.name}_toggle`}
          >
            <span className="mw-expanded-category-view__mw-nav-link" data-qa-id={props.dataQaId}>
              {props.display_name}
            </span>
            {props.categories.length > 0 ? (
              <PB_Chevron ratio={0.5} direction={open ? 'down' : 'right'} />
            ) : null}
          </button>
          {open ? renderCategories('expandable-subsubcategory') : null}
        </>
      ) : (
        renderCategories('expandable-subcategory')
      )}
    </div>
  );
};

MWExpandedCategoryView.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  category_id: PropTypes.string.isRequired,
  category_names: PropTypes.arrayOf(PropTypes.string),
  display_name: PropTypes.string,
  display_order_num: PropTypes.number,
  name: PropTypes.string,
  sub_groups: PropTypes.number,
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['invitation', 'card']).isRequired,
  dataQaId: PropTypes.string.isRequired,
  linkTabIndex: PropTypes.number,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
};
