import {useCallback, useState} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {PB_AccountLine as PBAccountLine} from '~/common/svg/PB_AccountLine';
import {PB_ActivityFeedLine as PBActivityFeedLine} from '~/common/svg/PB_ActivityFeedLine';
import {PB_AlertLine as PBAlertLine} from '~/common/svg/PB_AlertLine';
import {PB_Arrow as PBArrow} from '~/common/svg/PB_Arrow';
import {PB_CalendarLine as PBCalendarLine} from '~/common/svg/PB_CalendarLine';
import {PB_ClickOutLine as PBClickOutLine} from '~/common/svg/PB_ClickOutLine';
import {PB_CloseLine as PBCloseLine} from '~/common/svg/PB_CloseLine';
import {PB_EditLine as PBEditLine} from '~/common/svg/PB_EditLine';
import {PB_FavoritesLine as PBFavoritesLine} from '~/common/svg/PB_FavoritesLine';
import {PB_GuestListLine as PBGuestListLine} from '~/common/svg/PB_GuestListLine';
import {PB_LockLine as PBLockLine} from '~/common/svg/PB_LockLine';
import {PB_SettingsLine as PBSettingsLine} from '~/common/svg/PB_SettingsLine';
import {PB_EnvelopeSent as PBEnvelopeSent} from '~/common/svg/PB_EnvelopeSent';

import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {AcctDrawerItem} from '~/MegaNav/AcctDrawerItem/AcctDrawerItem';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {UserCallout} from './UserCallout';

import './AccountDetailsDrawer.sass';
import {largishAndUpQuery, extraLargeAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';

const parser = new DOMParser();

export const AccountDetailsDrawer = ({
  user,
  open,
  onClose,
  onLogout,
  navDesignVariationDesktop,
}) => {
  const {matches: lgWindow} = useMatchQuery(`(${extraLargeAndUpQuery})`);
  const {matches: largishScreen} = useMatchQuery(`(${largishAndUpQuery})`);
  const fullName = user.full_name || user.userName;
  const hasUserImage =
    (user.image_url && user.image_url !== 'None') || (user.imgUrl && user.imgUrl !== 'None');
  const userImage = user.image_url || user.imgUrl;

  const [mwAcctSettingsOpen, setMwAcctSettingsOpen] = useState(false);

  /** Handle clicking Account Settings in the MW account details drawer */
  const onMwAcctSettingsClick = useCallback(
    () => setMwAcctSettingsOpen(true),
    [setMwAcctSettingsOpen]
  );

  /** Handle clicking the back arrow in Account Settings in MW */
  const onMwAcctSettingsBackArrowClick = useCallback(() => {
    setMwAcctSettingsOpen(false);
  }, [setMwAcctSettingsOpen]);

  return (
    <div
      className={cx(
        'acct-details-drawer',
        lgWindow ? `redesign-${navDesignVariationDesktop}` : 'full-height',
        {open}
      )}
      id="acctDetails"
    >
      {!lgWindow && (
        <>
          {mwAcctSettingsOpen ? (
            <div className="acct-details-drawer__header">
              <button
                className="unstyled-button"
                onClick={onMwAcctSettingsBackArrowClick}
                data-qa-id="closeAccountSettingsButton"
                aria-label="Close account settings"
                type="button"
              >
                <PBArrow ratio={0.75} direction="left" />
              </button>
              <Typography as="h3" variant="header3" data-qa-id="accountSettingsHeaderText">
                Account settings
              </Typography>
            </div>
          ) : (
            <div className="acct-details-drawer__header">
              <button
                className="unstyled-button"
                onClick={onClose}
                tabIndex={open ? 0 : -1}
                type="button"
                data-qa-id="closeAccountSettingsButton"
                aria-label="Close account settings"
              >
                <PBCloseLine ratio={0.75} />
              </button>
              <div className="acct-details-drawer__user-details">
                <UserCallout name={fullName} className="acct-details-drawer__user-name" />
                <div className="nav-avatar big">
                  {hasUserImage ? (
                    <img src={userImage} alt="Profile" />
                  ) : (
                    <Typography
                      as="p"
                      variant="paragraph2"
                      data-qa-id="accountSettingsUserAvatarInitials"
                    >
                      {user.initials}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          )}
          <Divider marginTop="1rem" marginBottom="1rem" />
        </>
      )}
      <div className="acct-details-drawer__cta-group">
        {lgWindow || !mwAcctSettingsOpen ? (
          <>
            {lgWindow && ['B', 'C'].includes(navDesignVariationDesktop) ? (
              <UserCallout name={fullName} className="acct-details-drawer__user-name" />
            ) : null}
            <AcctDrawerItem
              href="/my-events"
              text="My Invitations"
              icon={<PBCalendarLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="myEventsAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/my-events?type=signup-sheets"
              text="My SignUp Sheets"
              icon={<PBActivityFeedLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="mySignUpSheetsAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/my-events?type=card"
              text="My eCards"
              icon={<PBEnvelopeSent ratio={lgWindow ? 0.54 : 0.72} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="myECardsAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/gallery/favorites"
              text={`My favorites${
                window.favoritesNumber !== undefined ? ` (${window.favoritesNumber})` : ''
              }`}
              icon={<PBFavoritesLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="myFavoritesAcctDrawerItem"
            />
            {largishScreen && (
              <AcctDrawerItem
                href="/profile/contacts"
                text="Address book"
                icon={<PBGuestListLine ratio={lgWindow ? 0.75 : 1} />}
                tabIndex={open ? 0 : -1}
                data-qa-id="addressBookAcctDrawerItem"
              />
            )}
            <AcctDrawerItem
              icon={<PBSettingsLine ratio={lgWindow ? 0.75 : 1} />}
              text="Account settings"
              type={lgWindow ? 'link' : 'button'}
              onClick={onMwAcctSettingsClick}
              href="/settings/account"
              tabIndex={open ? 0 : -1}
              data-qa-id="accountSettingsAcctDrawerItem"
            />
            <AcctDrawerItem
              icon={<PBClickOutLine ratio={lgWindow ? 0.75 : 1} />}
              text="Sign out"
              onClick={onLogout}
              type="button"
              tabIndex={open ? 0 : -1}
              data-qa-id="signOutAcctDrawerItem"
            />
          </>
        ) : (
          <>
            <AcctDrawerItem
              href="/profile/edit"
              text="Edit profile"
              icon={<PBEditLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="editProfileAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/profile"
              text="Account info"
              icon={<PBAccountLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="accountInfoAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/settings/blocks"
              text="Manage block list"
              icon={<PBAlertLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="manageBlockListAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/settings/data_privacy"
              text="Data privacy"
              icon={<PBLockLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="dataPrivacyAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/settings/pm_order_history"
              text="Your orders"
              icon={<PBActivityFeedLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={open ? 0 : -1}
              data-qa-id="yourOrdersAcctDrawerItem"
            />
          </>
        )}
      </div>
    </div>
  );
};

AccountDetailsDrawer.propTypes = {
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onLogout: PropTypes.func,
  navDesignVariationDesktop: PropTypes.oneOf(['control', 'B', 'C']).isRequired,
};
