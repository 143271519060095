import React, {createElement, Fragment, useEffect, useRef} from 'react';
import {createRoot} from 'react-dom/client';
import PropTypes from 'prop-types';
import {autocomplete} from '@algolia/autocomplete-js';

const addQaIdLocator = (containerRef, selector, qaId) => {
  const el = containerRef.current.querySelector(selector);
  if (el) {
    el.setAttribute('data-qa-id', qaId);
  }
};

export const AutoCompleteSearchBar = ({classNames, ...props}) => {
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    const search = autocomplete({
      container: containerRef.current,
      detachedMediaQuery: 'none',
      renderer: {createElement, Fragment, render: () => {}},
      renderNoResults({render: renderElem, root}) {
        renderElem(null, root);
      },
      render({elements, html}, root) {
        const {suggestion, template} = elements;
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }
        panelRootRef.current.render(
          html`<div className=${classNames.panelLayout}>
            <div>
              <div>${suggestion}</div>
              <div>${template}</div>
            </div>
          </div>`
        );
      },
      classNames,
      ...props,
    });

    addQaIdLocator(containerRef, '.search-bar__input', 'input-search-bar');
    addQaIdLocator(
      containerRef,
      '.aa-SubmitButton.search-bar__input--icons',
      'submitSearchQueryIconButton'
    );

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div id="autocomplete-input" data-qa-id="meganavSearchInput" ref={containerRef} />;
};

AutoCompleteSearchBar.propTypes = {
  classNames: PropTypes.objectOf(PropTypes.string),
};
