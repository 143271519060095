/* eslint-disable react/forbid-prop-types */
import cx from 'classnames';
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import './CategoryDrawer.sass';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {MWCategoryView} from '~/MegaNav/MWCategoryView/MWCategoryView';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';

export const CategoryDrawer = ({
  open = false,
  onBackdropClick,
  invitationCategories,
  greetingCardCategory,
  eGiftCardCategory,
  signupSheetCategory,
}) => {
  const [openCategory, setOpenCategory] = useState('');

  const toggleCategory = (category) => {
    if (openCategory === category) setOpenCategory('');
    else setOpenCategory(category);
  };
  return (
    <>
      <button
        className={cx('unstyled-button', 'nav-drawer-overlay', {
          'drawer-open': open,
        })}
        onClick={onBackdropClick}
        type="button"
        aria-label="Category drawer backdrop"
      />
      <div
        className={`mw-category-drawer${open ? ' drawer-open' : ''}`}
        style={{
          height: '100vh',
        }}
      >
        <div className="mw-category-drawer__header">
          <Typography as="h3" variant="header3" data-qa-id="browseDesignsHeaderText">
            Evite
          </Typography>
          <Button
            variant="unstyled-button"
            onClick={onBackdropClick}
            data-qa-id="close-category-drawer"
          >
            <PB_CloseLine ratio={0.75} />
          </Button>
        </div>
        <Divider />
        <div className="mw-category-container">
          {invitationCategories.map(
            (ic) =>
              (!ic.mode || ic.mode === 'mobile') && (
                <MWCategoryView
                  {...ic}
                  key={ic.id}
                  hideSubCategories={!!ic.hideSubCategories}
                  type="invitation"
                  linkTabIndex={open ? 0 : -1}
                  open={openCategory === ic.title.toLowerCase().replaceAll(' ', '-')}
                  onToggle={toggleCategory}
                  newCategory={ic.new}
                />
              )
          )}
          <MWCategoryView
            title={greetingCardCategory.title}
            href={greetingCardCategory.href}
            key={greetingCardCategory.id}
            id={greetingCardCategory.id}
            hideSubCategories={!!greetingCardCategory.hideSubCategories}
            dataQaId={greetingCardCategory.dataQaId}
            subCategories={greetingCardCategory.subCategories}
            type="card"
            linkTabIndex={open ? 0 : -1}
            open={openCategory === greetingCardCategory.title.toLowerCase().replaceAll(' ', '-')}
            onToggle={toggleCategory}
            newCategory={greetingCardCategory.new}
          />
          {eGiftCardCategory ? (
            <MWCategoryView
              title={eGiftCardCategory.title}
              href={eGiftCardCategory.href}
              key={eGiftCardCategory.id}
              id={eGiftCardCategory.id}
              hideSubCategories={!!eGiftCardCategory.hideSubCategories}
              dataQaId={eGiftCardCategory.dataQaId}
              subCategories={eGiftCardCategory.subCategories}
              type="card"
              linkTabIndex={open ? 0 : -1}
              open={openCategory === eGiftCardCategory.title.toLowerCase().replaceAll(' ', '-')}
              onToggle={toggleCategory}
              newCategory={eGiftCardCategory.new}
            />
          ) : null}
          {signupSheetCategory ? (
            <MWCategoryView
              title={signupSheetCategory.title}
              href={signupSheetCategory.href}
              key={signupSheetCategory.id}
              id={signupSheetCategory.id}
              hideSubCategories={!!signupSheetCategory.hideSubCategories}
              dataQaId={signupSheetCategory.dataQaId}
              subCategories={signupSheetCategory.subCategories}
              type="card"
              linkTabIndex={open ? 0 : -1}
              open={openCategory === signupSheetCategory.title.toLowerCase().replaceAll(' ', '-')}
              onToggle={toggleCategory}
              newCategory={signupSheetCategory.new}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

CategoryDrawer.propTypes = {
  open: PropTypes.bool,
  onBackdropClick: PropTypes.func.isRequired,
  invitationCategories: PropTypes.arrayOf(
    PropTypes.shape({
      dataQaId: PropTypes.string.isRequired,
      hideSubCategories: PropTypes.bool,
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      mode: PropTypes.string,
      new: PropTypes.bool,
      subCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  greetingCardCategory: PropTypes.shape({
    dataQaId: PropTypes.string.isRequired,
    hideSubCategories: PropTypes.bool,
    href: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    mode: PropTypes.string,
    new: PropTypes.bool,
    subCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  eGiftCardCategory: PropTypes.shape({
    dataQaId: PropTypes.string.isRequired,
    hideSubCategories: PropTypes.bool,
    href: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    mode: PropTypes.string,
    new: PropTypes.bool,
    subCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
  }),
  signupSheetCategory: PropTypes.shape({
    dataQaId: PropTypes.string.isRequired,
    hideSubCategories: PropTypes.bool,
    href: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    mode: PropTypes.string,
    new: PropTypes.bool,
    subCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
  }),
};
