import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {UserCallout} from '../AccountDetailsDrawer/UserCallout';
import {useIsLargeAndUp} from '~/common/hooks/useMatchQuery';
import './AccountPreview.sass';

const navDesignVariationDesktop = evite.experiments.getOrSetVariation(
  'redesign-meganav-desktop',
  'control'
);

export const AccountPreview = ({user, onClick}) => {
  const displayName = user.first_name || user.firstName;
  const hasUserImage =
    (user.image_url && user.image_url !== 'None') || (user.imgUrl && user.imgUrl !== 'None');
  const userImage = user.image_url || user.imgUrl;

  const isLargeAndUp = useIsLargeAndUp();

  return (
    <button
      className="unstyled-button acct-details"
      onClick={onClick}
      data-qa-id="openAccountDetailsButton"
      type="button"
    >
      {navDesignVariationDesktop === 'control' && isLargeAndUp ? (
        <UserCallout name={displayName} className="nav-user-name" dataQaId="userDisplayName" />
      ) : null}

      <div data-for="myEventsMovedTooltip">
        <div className="nav-avatar">
          {hasUserImage ? (
            <img src={userImage} alt="Profile" />
          ) : (
            <Typography as="p" variant="paragraph4" data-qa-id="userAvatarInitials">
              {user.initials}
            </Typography>
          )}
        </div>
      </div>
    </button>
  );
};

AccountPreview.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
};
