import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NewCategoryTag} from '~/MegaNav/NewCategoryTag/NewCategoryTag';
import {MWExpandedCategoryView} from '~/MegaNav/MWExpandedCategoryView/MWExpandedCategoryView';
import {PB_Chevron} from '~/common/svg/PB_Chevron';

import './MWCategoryView.sass';

export const MWCategoryView = ({
  open: defaultOpen = false,
  title,
  href: defaultHref,
  id,
  hideSubCategories = false,
  dataQaId,
  linkColor = null,
  linkHoverColor = null,
  linkTabIndex,
  type,
  newCategory = false,
  subCategories = [],
  onToggle,
}) => {
  const [openOverride, setOpenOverride] = useState(false);
  const [openSubcategory, setOpenSubcategory] = useState('');

  useEffect(() => {
    if (!defaultOpen) {
      setOpenOverride(false);
      setOpenSubcategory('');
    }
  }, [defaultOpen]);

  const toggleCategory = (category) => {
    if (onToggle) {
      onToggle(category);
    } else {
      setOpenOverride(!openOverride);
    }
  };

  const toggleSubcategory = (subcategory) => {
    if (openSubcategory === subcategory) setOpenSubcategory('');
    else setOpenSubcategory(subcategory);
  };

  const goToCategory = () => {
    let href = defaultHref || '';
    if (!href) return '';
    if (href.startsWith('/')) {
      href = `${window.location.protocol}//${window.location.hostname}${href}`;
    }
    const url = new URL(href);
    url.searchParams.append('cat', id);
    window.location.href = url.toString();
    return undefined;
  };

  const open = defaultOpen || openOverride;

  const categoryTitle = title.toLowerCase().replaceAll(' ', '-');

  return hideSubCategories ? (
    <a
      key={`${title.toLowerCase().replaceAll(' ', '-')}_cat_link`}
      href={defaultHref}
      id={id}
      data-qa-id={dataQaId}
      className="unstyled-button expandable-category"
    >
      <div
        className="mw-category-view__mw-nav-link"
        style={{
          '--main-nav-link-color': linkColor || null,
          '--main-nav-link-hover-color': linkHoverColor || null,
        }}
        key={`${title.toLowerCase().replaceAll(' ', '-')}_link`}
      >
        {title}
        {newCategory ? <NewCategoryTag /> : null}
      </div>
      <PB_Chevron ratio={0.5} direction={open ? 'down' : 'right'} />
    </a>
  ) : (
    <div>
      <button
        className="unstyled-button expandable-category"
        onClick={() => toggleCategory(categoryTitle)}
        tabIndex={linkTabIndex}
        data-qa-id={`${categoryTitle}_toggle`}
        type="button"
      >
        <span
          className="mw-category-view__mw-nav-link"
          id={id}
          style={{
            '--main-nav-link-color': linkColor || null,
            '--main-nav-link-hover-color': linkHoverColor || null,
          }}
          data-qa-id={dataQaId}
          key={`${categoryTitle}_link`}
        >
          {title}
          {newCategory ? <NewCategoryTag /> : null}
        </span>
        <PB_Chevron ratio={0.5} direction={open ? 'down' : 'right'} />;
      </button>

      {open ? (
        <div className="subcategory-wrapper">
          <MWExpandedCategoryView
            categories={[]}
            category_id={id}
            category_names={[]}
            display_name={`All ${title}`}
            display_order_num={0}
            name={id}
            sub_groups={0}
            visible
            type={type}
            dataQaId={`categoryView_${id}`}
            open={false}
            onToggle={goToCategory}
          />
          {subCategories.map((sc) => (
            <MWExpandedCategoryView
              {...sc}
              category_id={id}
              type={type}
              key={sc.name}
              dataQaId={`expandedCategoryView_${sc.id}`}
              open={openSubcategory === sc.name}
              onToggle={toggleSubcategory}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

MWCategoryView.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataQaId: PropTypes.string,
  hideSubCategories: PropTypes.bool,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      categories: PropTypes.arrayOf(PropTypes.object),
      category_names: PropTypes.arrayOf(PropTypes.string),
      display_name: PropTypes.string,
      display_order_num: PropTypes.number,
      name: PropTypes.string,
      sub_groups: PropTypes.number,
      visible: PropTypes.bool,
    })
  ),
  type: PropTypes.oneOf(['invitation', 'card']).isRequired,
  linkTabIndex: PropTypes.number,
  linkColor: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  linkHoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  newCategory: PropTypes.bool,
};
