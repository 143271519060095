export const PACKAGE_ID_EVITE_PRO = 'evite_pro_yearly';
export const PACKAGE_ID_GREETING_CARD_YEARLY = 'greeting_card_subscription_annual';
export const PLAN_NAME_GREETING_CARD_SUBSCRIPTION = 'greeting_card_subscription';
export const PLAN_NAME_PARTY_PASS = 'party_pass';
export const PLAN_NAME_PRO = 'pro';
export const PLAN_NAME_PREMIUM_PASS = 'premium_pass';
export const PLAN_NAME_PREMIUM_PASS_SUBSCRIPTION = 'premium_pass_subscription';
export const PAYMENT_TYPE_CREDIT = 'credit';
export const PAYMENT_TYPE_PAYPAL = 'paypal';
export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const EVITE_PRO_NAME = 'Evite Pro';
export const GREETING_CARD_SUBSCRIPTION_NAME = 'Greeting Card';
export const PREMIUM_PASS_NAME = 'Premium Pass';
export const PARTY_PASS_NAME = 'Party Pass';
export const FRIENDLY_NAMES = {
  [PACKAGE_ID_EVITE_PRO]: EVITE_PRO_NAME,
  [PLAN_NAME_PRO]: EVITE_PRO_NAME,
  [PLAN_NAME_PARTY_PASS]: PARTY_PASS_NAME,
  [PLAN_NAME_PREMIUM_PASS]: PREMIUM_PASS_NAME,
  [PLAN_NAME_PREMIUM_PASS_SUBSCRIPTION]: PREMIUM_PASS_NAME,
  [PACKAGE_ID_GREETING_CARD_YEARLY]: GREETING_CARD_SUBSCRIPTION_NAME,
  [PLAN_NAME_GREETING_CARD_SUBSCRIPTION]: GREETING_CARD_SUBSCRIPTION_NAME,
};

export const PACKAGES_VARIANT = [
  {
    name: 'Silver',
    description: 'Send to <strong>12 guests*</strong>',
    subDescription: null,
    amount: 1799,
    packageId: 'buy_12_invitations_for_1799_silver',
    displayPrice: '$17.99',
    variableQuantity: false,
    isSubscription: false,
    default: false,
    tag: null,
  },
  {
    name: 'Gold (Recommended)',
    description: 'Send to <strong>30 guests*</strong>',
    subDescription: null,
    amount: 3699,
    packageId: 'buy_30_invitations_for_3699_gold',
    displayPrice: '$36.99',
    variableQuantity: false,
    isSubscription: false,
    default: true,
    tag: null,
  },
  {
    name: 'Platinum',
    description: 'Send to <strong>75 guests*</strong>',
    subDescription: null,
    amount: 6899,
    packageId: 'buy_75_invitations_for_6899_platinum',
    displayPrice: '$68.99',
    variableQuantity: false,
    isSubscription: false,
    default: false,
    tag: null,
  },
  {
    name: 'Diamond',
    description: 'Send to <strong>750 guests*</strong>',
    subDescription: null,
    amount: 9999,
    packageId: 'buy_750_invitations_for_9999_diamond',
    displayPrice: '$99.99',
    variableQuantity: false,
    isSubscription: false,
    default: false,
    tag: null,
  },
];
export const EVITE_PRO_PACKAGE_VARIANT = {
  name: 'Evite Pro',
  description:
    '<div>Unlimited Invitations and eCards</div> <div style="padding-top: 0.25rem">Send to <strong>2500 guests</strong> per event</div>',
  subDescription: 'Billed annually',
  amount: 24999,
  packageId: 'evite_pro_yearly',
  displayPrice: '$249.99',
  variableQuantity: false,
  isSubscription: true,
  default: false,
  tag: null,
};
