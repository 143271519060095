import React, {useEffect, useMemo, useRef} from 'react';
import Select, {components} from 'react-select';
import PropTypes from 'prop-types';
import {
  charcoal,
  cottonCandy,
  emeraldWebText,
  fog,
  ghost,
  silver,
} from '~sass/pb_styleguide/base/_exports.sass';
import {PB_Checkmark as CheckmarkIcon} from '~/common/svg/PB_Checkmark';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';

const CategoryPickerValueContainer = (vcProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.ValueContainer {...vcProps} data-qa-id="meganavSearchCategorySelector" />
);

const SearchControl = (props) => (
  // eslint-disable-next-line react/destructuring-assignment,react/jsx-props-no-spreading
  <components.Control {...props} data-qa-id={props.selectProps.categoryPickerDataQaId} />
);

export const SearchDropdown = ({
  categories,
  selectedCategory,
  onCategorySwitch,
  categoryPickerDataQaId,
  isInline,
}) => {
  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);
  const controlRef = useRef(null);

  // eslint-disable-next-line react/no-unstable-nested-components
  const CategoryOption = (optionProps) => {
    const {label} = optionProps;
    const isSelected = label === selectedCategory;

    return (
      <components.Option
        {...optionProps}
        className={`search-select-option${label === categories[0] ? ' no-separator' : ''}`}
        innerProps={{
          // eslint-disable-next-line react/destructuring-assignment
          ...optionProps.innerProps,
          'data-qa-id': `searchCategory_${label.replaceAll(' ', '_')}`,
        }}
        isSelected={isSelected}
      >
        {label}
        {isSelected && <CheckmarkIcon ratio={0.5} color={emeraldWebText} />}
      </components.Option>
    );
  };

  useEffect(() => {
    if (controlRef.current) {
      controlRef.current.onControlTouchEnd = () => {
        controlRef.current.userIsDragging = true;
      };
    }
  }, [controlRef]);

  const selectStyles = useMemo(
    () => ({
      indicatorSeparator: () => ({}),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: charcoal,
      }),
      placeholder: (provided) => ({
        ...provided,
        color: charcoal,
      }),
      container: (provided) => ({
        ...provided,
        minWidth: lgWindow ? '12rem' : '9rem',
        width: lgWindow ? '12rem' : '9rem',
        height: isInline ? '3.5rem' : '3rem',
      }),
      control: (provided, {isDisabled}) => ({
        ...provided,
        height: '100%',
        paddingLeft: '1rem',
        paddingRight: 7,
        fontFamily: 'Brut',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.25rem',
        borderColor: silver,
        borderWidth: 'thin',
        boxShadow: undefined,
        backgroundColor: isDisabled ? fog : ghost,
        borderRadius: isInline ? '0.5rem' : '0.25rem',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none',
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        justifyContent: isInline ? 'center' : undefined,
        flex: isInline ? 'unset' : 1,
      }),
      singleValue: (provided) => ({
        ...provided,
        justifySelf: isInline ? 'center' : 'flex-start',
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0.5rem',
      }),
      menuList: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        borderRadius: '0.5rem',
      }),
      option: (provided, {isSelected}) => ({
        ...provided,
        fontFamily: 'Brut',
        fontWeight: 400,
        color: isSelected ? emeraldWebText : charcoal,
        backgroundColor: ghost,
        ':hover': {
          backgroundColor: cottonCandy,
        },
        fontSize: '1rem',
        lineHeight: '1.25rem',
      }),
    }),
    [isInline, lgWindow]
  );

  return (
    <div className="search-dropdown">
      <Select
        categoryPickerDataQaId={categoryPickerDataQaId}
        components={{
          Option: CategoryOption,
          ValueContainer: CategoryPickerValueContainer,
          Control: SearchControl,
        }}
        defaultValue={selectedCategory}
        isSearchable={false}
        onChange={(newValue) => onCategorySwitch(newValue.value)}
        options={categories.map((c) => ({label: c, value: c}))}
        placeholder={selectedCategory}
        ref={controlRef}
        styles={selectStyles}
      />
    </div>
  );
};

SearchDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string,
  onCategorySwitch: PropTypes.func,
  categoryPickerDataQaId: PropTypes.string,
  isInline: PropTypes.bool,
};
