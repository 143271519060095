// TODO: TI-173 (https://evitetracking.atlassian.net/browse/TI-173) to write tests for this bridge function
export const runWebviewToAppBridge = (channel, message) => {
  if (channel in window) {
    // flutter; do not even try ios/android; return after trying to post message
    try {
      window[channel]?.postMessage?.(message);
    } catch (e) {
      evite.error(e);
    }
    return;
  }

  // ios
  try {
    window.webkit?.messageHandlers?.[channel]?.postMessage?.(message);
  } catch (e) {
    evite.error(e);
  }

  // android
  try {
    window.webview?.[channel]?.(message);
  } catch (e) {
    evite.error(e);
  }
};

const BASE_WEBVIEW_CHANNELS = [
  'fabricCreateOnNav', // invitation create/edit flow
  'viewInvitationSetRsvp', // view invitation flow
];

export const isFabricUniversalWebview = (extraChannelsToWatch = []) =>
  [...BASE_WEBVIEW_CHANNELS, ...extraChannelsToWatch].some((channel) => window?.[channel]);
export const isFabricIOSWebview = (extraChannelsToWatch = []) =>
  [...BASE_WEBVIEW_CHANNELS, ...extraChannelsToWatch].some(
    (channel) => window?.webkit?.messageHandlers?.[channel]
  );
export const isFabricAndroidWebview = (extraChannelsToWatch = []) =>
  [...BASE_WEBVIEW_CHANNELS, ...extraChannelsToWatch].some((channel) => window?.webview?.[channel]);

export const isFabricWebview = (extraChannelsToWatch = []) =>
  !!(
    isFabricUniversalWebview(extraChannelsToWatch) ||
    isFabricIOSWebview(extraChannelsToWatch) ||
    isFabricAndroidWebview(extraChannelsToWatch)
  );

export const isLegacyFabricWebview = () =>
  !!(window?.webkit?.messageHandlers?.fabricCreateOnNav || window?.webview?.fabricCreateOnNav);
